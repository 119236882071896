import React from 'react'

class Footer extends React.Component {
    render() {
		const year = new Date().getFullYear();

        return (
            <div id="footer">
                <div className="inner">
                    <ul className="icons">
                        <li><a href="https://www.twitter.com/jaccardo_art" target="_blank" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
                        <li><a href="https://www.instagram.com/j.accardo_art/" target="_blank" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
                        {/* <li><a href="#" className="icon fa-github"><span className="label">Github</span></a></li>
                        <li><a href="#" className="icon fa-dribbble"><span className="label">Dribbble</span></a></li> */}
                        <li><a href="mailto:j.accardo@joseph-accardo.com" className="icon fa-envelope-o"><span className="label">Email</span></a></li>
                    </ul>
                    <ul className="copyright">
						<li>&copy; {year.toString()} Joseph Accardo</li>
						<li>Icon by Freepik</li>
                        {/* <li>&copy; Gatsby Starter Strata</li>
						<li>Design: <a href="http://html5up.net">HTML5 UP</a></li> */}
                    </ul>
                </div>
            </div>
        )
    }
}

export default Footer
