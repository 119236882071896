import React from 'react'

import Footer from './Footer'
//import avatar from '../assets/images/avatar.jpg'
import avatar from '../assets/images/joey.jpg'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
					<br /><br /><br /><br /><br />
                    {/* <a href="/" className="image avatar"><img src={avatar} alt="" /></a> */}
                    {/* <h1>
						<strong>Joseph Accardo</strong>
						<br />
						Prismacolor Sports Artist
						<br />
						Husband
						<br />
						Father of Two Beautiful Girls
					</h1> */}
                </div>
                <Footer />
            </header>
        )
    }
}

export default Header
